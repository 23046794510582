<template>
  <div class="croupiers">
    <div class="custom-shape-divider-top-1602545694">
      <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
        <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" class="shape-fill"></path>
      </svg>
    </div>
    
    <div class="int">
      <h2>Rotation des croupiers</h2>
      
      <div class="croupier">
        <div class="id">
          <div class="left">
            <span class="bj etat"></span> <span class="duration">1h05</span> Coquelet
          </div>
          <div class="right">
            <div class="right">
              <span class="variation green">
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="24" height="24" viewBox="0 0 24 24"><path d="M13,20H11V8L5.5,13.5L4.08,12.08L12,4.16L19.92,12.08L18.5,13.5L13,8V20Z" /></svg>
                2 000€</span>
            </div>
          </div>
        </div>
        <div class="liste">
          <div class="elem">
            <div class="left"><span class="hour bj">21h45</span><span class="duration">25 min</span> RA1</div>
            <div class="right red">
<!--              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="24" height="24" viewBox="0 0 24 24"><path d="M11,4H13V16L18.5,10.5L19.92,11.92L12,19.84L4.08,11.92L5.5,10.5L11,16V4Z" /></svg>-->
              -500€
            </div> 
          </div>
          <div class="elem">
            <div class="left">
              <span class="hour ra">22h00</span><span class="duration">40 min</span> BJ1
            </div>
            <div class="right green">
<!--              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="24" height="24" viewBox="0 0 24 24"><path d="M13,20H11V8L5.5,13.5L4.08,12.08L12,4.16L19.92,12.08L18.5,13.5L13,8V20Z" /></svg> -->
              +2 500€
            </div>
          </div>
        </div>
      </div>
      <div class="croupier">
        <div class="id">
          <div class="left">
            <span class="thp etat"></span> <span class="duration">5min</span> Vanderosieren
          </div>
          <div class="right">
            <div class="right">
              <span class="variation red">
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="24" height="24" viewBox="0 0 24 24"><path d="M11,4H13V16L18.5,10.5L19.92,11.92L12,19.84L4.08,11.92L5.5,10.5L11,16V4Z" /></svg>
                5 420€</span>
            </div>
          </div>
        </div>
        <div class="liste">
          <div class="elem">
            <div class="left">
              <span class="hour bj">23h15</span><span class="duration">5 min</span> RA2
            </div>
            <div class="right red">
<!--              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="24" height="24" viewBox="0 0 24 24"><path d="M11,4H13V16L18.5,10.5L19.92,11.92L12,19.84L4.08,11.92L5.5,10.5L11,16V4Z" /></svg>-->
              -5 420€
            </div>
          </div>
        </div>
      </div>
      
    </div>
  </div>
</template>

<script>
export default {
  
}
</script>

<style scoped>

  .croupiers .int { padding-top: 58px;}
  
  .croupiers h2 { padding-left: 16px; }

  .croupier { margin-bottom: 4px; }
  .croupier .id { height: 44px; display: flex; align-items: center; justify-content: space-between; background: #f5f5f5; padding: 0 8px 0 16px }
  .croupier .id .left { display: flex; align-items: center }
  .croupier .id .right { display: flex; align-items: center }
  .croupier .id span.etat { color: #fff; border-radius: 20%; font-size: 14px; width: 22px; height: 22px; margin-right: 6px;}
  .croupier .liste { padding-left: 10px; padding-right: 16px; margin-left: 26px; position: relative; padding-top: 16px; margin-bottom: 28px; }
  .croupier .liste:before { content: ''; position: absolute; top: 0; bottom: 15px; left: 0px; width: 2px; background: #ccc }
  .croupier .liste .elem { height: 30px; display: flex; align-items: center; justify-content: space-between; position: relative; padding-left: 10px; }
  .croupier .liste .elem .right { display: flex; align-items: center;}
  .croupier svg { height: 16px; padding-right: 2px; }
  .croupier .liste .elem:before { content: ''; position: absolute; top: 50%; transform: translateY(-50%); left: -10px; width: 10px; height: 2px; background: #ccc }
  .croupier .liste .elem span:not(.duration) { color: #fff; padding: 0 6px; border-radius: 4px; margin-right: 8px; }
  .croupier span.duration { color: #444; background: #ddd; padding: 0 6px; border-radius: 4px; margin-right: 8px;  }
  .croupier span.variation { color: #444444; background: #fdfdfd; padding: 0 6px 0 2px; border-radius: 4px; display: flex; align-items: center; }
  .croupier .id span.variation { font-weight: 700; opacity: 1; }

.custom-shape-divider-top-1602545694 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.custom-shape-divider-top-1602545694 svg {
  position: relative;
  display: block;
  width: calc(208% + 1.3px);
  height: 63px;
  transform: rotateY(180deg);
}

.custom-shape-divider-top-1602545694 .shape-fill {
  fill: #cf4d49;
}
</style>